import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import ProductCard from '../ProductCard.jsx';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import DataFetchErrorPlaceholder from '../../../shared/DataFetchErrorPlaceholder.jsx';

const SectionContainer = styled.div`
  width: 97%;
  padding: 24px 0px 18px 32px;
  margin-top: 30px;
  margin-bottom: 20px;
  // border-radius: 8px;
  background-color: ${(props) => (props.$grayBackground ? '#f4f4f4' : '#fff')};

  @media (max-width: 1024px) {
    width: 96%;
  }
  @media (max-width: 430px) {
    width: 92%;
  }
`;

const SectionTitle = styled.p`
padding; 0;
margin: 0;
margin-bottom: 18px;
color: var(--color-gray1);
font-family: Univers;
font-size: 28px;
font-weight: 500;
`;

const SplideContainer = styled(Splide)`
  height: 250px;
  .splide__track {
    height: 250px;
  }

  .splide__arrow {
    background: none;
    border: 1.5px solid var(--color-primary);
  }

  .splide__arrow svg {
    fill: var(--color-primary);
    height: 1em;
    width: 1em;
  }

  .splide__arrow--prev {
    margin-left: -62px;

    @media (max-width: 1240px) {
      display: none;
    }
  }

  .splide__arrow--next {
    margin-right: -20px;

    @media (max-width: 1240px) {
      display: none;
    }
  }

  .splide__pagination__page {
    margin: 0 3px;
    background: white;
    border: 0.5px solid var(--color-gray3);
    height: 7px;
    width: 7px;
  }
  .splide__pagination__page.is-active {
    background: var(--color-primary);
    transform: none;
    opacity: 1;
    border: none;
  }
`;

const SkeletonCard = styled(Skeleton)`
  margin-left: 20px;
  // border-radius: 10px;
`;

const ErrorWindowContainer = styled.div`
  background: none;
  width: 100%;
  height: 238px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1px;
  margin-right: 40px;
  border: 1px solid #bfc6d1;
  box-shadow: 2px 3px 2px rgba(0, 0, 0, 0.2);
  // border-radius: 12px;
  @media (max-width: 502px) {
    width: 90%;
  }
`;

const ToolsCarousel = ({
  productName,
  loading,
  carouselName,
  grayBackground,
  loadingError,
}) => {
  const [shouldRemoveArrows, setShouldRemoveArrows] = useState(true);

  const splideOptions = {
    heightRatio: 0.5,
    arrows: !shouldRemoveArrows,
    pagination: false,
    gap: '20px',
    breakpoints: {
      4000: {
        perPage: 15,
      },
      3850: {
        perPage: 14,
      },
      3550: {
        perPage: 13,
      },
      3300: {
        perPage: 12,
      },
      3100: {
        perPage: 11,
      },
      2900: {
        perPage: 10,
      },
      2700: {
        perPage: 9,
      },
      2500: {
        perPage: 8,
      },
      2300: {
        perPage: 7,
      },
      2100: {
        perPage: 7,
      },
      1900: {
        perPage: 6,
      },
      1680: {
        perPage: 5,
      },
      1400: {
        perPage: 4,
      },
      1100: {
        perPage: 'auto',
      },
      430: {
        gap: '10px',
      },
    },
  };

  useEffect(() => {
    if (productName) {
      const screenWidth = window.innerWidth;
      const totalWidthRequired = productName.length * 220;

      if (totalWidthRequired <= screenWidth) {
        setShouldRemoveArrows(true);
      } else {
        setShouldRemoveArrows(false);
      }
    }
  }, [window.innerWidth, productName]);

  return (
    <SectionContainer $grayBackground={grayBackground}>
      <SectionTitle>{carouselName}</SectionTitle>
      {loadingError ? (
        <ErrorWindowContainer>
          <DataFetchErrorPlaceholder />
        </ErrorWindowContainer>
      ) : (
        <SplideContainer options={splideOptions}>
          {loading ? (
            <SplideSlide>
              <SkeletonCard height={238} width={190} />
            </SplideSlide>
          ) : (
            productName.length > 0 &&
            productName.map((product, index) => (
              <SplideSlide key={index}>
                <ProductCard
                  productName={product.className}
                  cardImage={product.ImagePath}
                />
              </SplideSlide>
            ))
          )}
        </SplideContainer>
      )}
    </SectionContainer>
  );
};

export default ToolsCarousel;
