/**
 * Helpers for accessing site configuration values.
 */

/**
 * Pattern for detecting placeholder values.
 * @type {RegEx}
 */
const placeholderPattern = /^%%[^%]+%%$/;

/**
 * Returns the specified value from the current configuration.
 *
 * @param {String} key Key for the value to be returned.
 * @param {Any} defaultValue Value to applied if the placeholder is detected (optional).
 *
 * @returns {Any} Configuration value.
 */
function get_config(key, defaultValue) {
  let value = window['__site_config'][key];

  if (
    !value ||
    ((typeof value === 'string' || value instanceof String) &&
      placeholderPattern.test(value))
  ) {
    value = defaultValue;
  }

  return value;
}


function get_config_bool(key, defaultValue) {
  var result = defaultValue;
  var value = get_config(key, defaultValue);

  if( typeof value === 'string' ) {
    value = value.toLowerCase();
    result = value == 'true' || value == '1';
  } else {
    result = value ? true : false;
  }
  
  console.log( `${key}=${result}` );
  return result;
}

/**
 * The identifier for the microsoft idps
 * @type {String}
 */
export const oktaIdpsMicrosoft = get_config('OKTA_IDPS_MICROSOFT');

/**
 * Whether to use okta classic or identity engine
 * @type {String}
 */
export const useIdentityEngine = get_config_bool('OKTA_IDENTITY_ENGINE', true);

/**
 * Okta client identifier.
 * @type {String}
 */
export const clientId = get_config('OKTA_CLIENTID');

/**
 * Valid Okta issuer.
 * @type {String}
 */
export const issuer = get_config('OKTA_ISSUER');

/**
 * URL for the Halliburton tools api.
 * @type {String}
 */
export const toolsApiUrl = get_config('TOOLS_API_URL');

/**
 * Default scope for the Halliburton tools api.
 * @type {String}
 */
export const toolsApiScope = get_config('TOOLS_API_SCOPE');

/**
 * URL for the Azure Function search API.
 * @type {String}
 */
export const searchApiUrl = get_config('SEARCH_API_URL');

/**
 * Authentication scope required for the Azure Function API.
 * @type {String}
 */
export const searchApiScope = get_config('SEARCH_API_SCOPE');

/**
 * True if verbose is enabled, false otherwise
 * @type {Boolean}
 */
export const isVerbose = get_config('VERBOSE', false);

/**
 * Duration after authentication is re-verified.
 * @type {Number}
 */
export const authenticationTimeout = get_config('VERIFY_INTERVAL', 3600);

/**
 * Application insights instrumentation key.
 * @type {String}
 */
export const appInsightsInstrumentation = get_config('AI_INSTRUMENTATION');

/**
 * URL for the chatbot.
 * @type {String}
 */
export const chatBotUrl = get_config('CHATBOT_URL');

/**
 * URL for the Haltrax.
 * @type {String}
 */
export const haltraxUrl = get_config('HALTRAX_URL');
