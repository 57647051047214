import React, { useContext, useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import AttributeCard from './AttributeCard';
import styled from 'styled-components';
import ToolCard from './ToolCard.jsx';
import { fetchFavorites } from '../../backend/favorites.js';
import { ErrorModalContext } from '../../Modals/ErrorModal.jsx';
import { useQuery } from 'react-query';
import { fetchTool } from '../../backend/tooldetails.js';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import '@splidejs/react-splide/css';
import { Splide, SplideSlide } from '@splidejs/react-splide';

const SkeletonLoaderContainer = styled.div`
  margin-left: 8px;
  margin-right: 12px;

  @media (max-width: 1024px) {
    margin: 0;
  }
`;

const SkeletonRow = styled(Skeleton)`
  margin-bottom: 10px;
`;

const Container = styled.div`
  background-color: var(--color-gray6);
  width: 100%;
  min-height: calc(100vh - 150px);
  padding-top: 12px;
  padding-bottom: 20px;

  @media (max-width: 1024px) {
    padding: 0;
    background-color: #fff;
  }
`;

const Carousel = styled(Splide)`
  padding-bottom: 36px;
`;

const Slide = styled(SplideSlide)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ToolComparisonContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 500px;
  font-family: Univers;
  margin-left: 112px;
  margin-right: 112px;
  background-color: white;
  pointer-events: ${(props) => (props.$isDisabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.$isDisabled ? '0.4' : '1')};
  z-index: 3;

  @media (max-width: 1024px) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`;

const ToolComparisonContainerDesktop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 19.4px;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0 12.2px;

  @media (max-width: 1024px) {
    display: none; /* Hide on mobile */
  }
`;

const ToolComparisonContainerMobile = styled.div`
  display: none;

  @media (max-width: 1024px) {
    display: block;
    padding: 15px;
  }
`;

const Title = styled.h1`
  margin: 20px 21px;
  font-family: Rubik;
  font-size: 28px;
  font-weight: 500;
  text-align: left;
  color: #2d3a4d;
`;

const SignInMessage = styled.div`
  height: 400px;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: var(--color-gray3);
  font-size: 28px;
  font-weight: 500;
`;

const index = () => {
  const [, setError] = useContext(ErrorModalContext);
  const params = new URLSearchParams(window.location.search);
  const toolsArr = JSON.parse(`[${params.get('tools')}]`);

  const { authState } = useOktaAuth();

  const { data: favoritesData, refetch } = useQuery(
    'fetchFavorites',
    fetchFavorites,
    {
      onError: (error) => {
        const errorCode = error.message.split('/');
        if (error.constructor.name === 'TypeError') {
          setError({
            title: 'Looks like something went wrong',
            message:
              'Network connection lost. Please check your network connection and try again.',
          });
        } else if (errorCode[1] !== '401') {
          setError({
            title: 'Looks like something went wrong',
            message:
              'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
            errorCode: `#FA-1-${errorCode[1]}`,
          });
        }
      },
    }
  );

  const referenceToolId = toolsArr[0];

  const [attrArray, setAttrArray] = useState([]);

  const getAttr = async () => {
    try {
      const refData = await fetchTool(referenceToolId);
      let sample = [...refData.DescriptionLite];

      if (sample.length < 15) {
        for (const tool of toolsArr) {
          const data = (await fetchTool(tool)).DescriptionLite;
          data.forEach((a) => {
            if (
              !sample.some((el) => el.Name === a.Name) &&
              sample.length < 15
            ) {
              sample.push(a);
            }
          });
        }
      }

      setAttrArray(sample);
    } catch (error) {
      console.error('Error fetching attributes:', error);
    }
  };

  const {
    data,
    isLoading,
    error,
    refetch: refetchToolsData,
  } = useQuery(`tool-${referenceToolId}`, () => fetchTool(referenceToolId), {
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      getAttr();
    },
    onError: (error) => {
      const errorCode = error.message.split('/');
      setError(
        error.constructor.name === 'TypeError'
          ? {
              title: 'Looks like something went wrong',
              message:
                'Network connection lost. Please check your network connection and try again.',
            }
          : {
              title: 'Looks like something went wrong',
              message:
                'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
              errorCode: `#TO-1-${errorCode[1]}`,
            }
      );
    },
  });

  useEffect(() => {
    refetchToolsData();
  }, []);

  return (
    <Container>
      {!authState?.isAuthenticated ? (
        <SignInMessage>
          Please sign into your account to access this feature
        </SignInMessage>
      ) : (
        <>
          {error && <h1>Tool not found</h1>}
          <ToolComparisonContainer>
            <Title>Tool comparison</Title>

            {/* Desktop View */}
            <ToolComparisonContainerDesktop>
              <AttributeCard attrArray={attrArray} />
              {toolsArr.map((toolId) =>
                isLoading ? (
                  <SkeletonLoaderContainer key={toolId}>
                    <SkeletonRow height={550} width={200} count={1} />
                  </SkeletonLoaderContainer>
                ) : (
                  <ToolCard
                    key={toolId}
                    firstTool={data}
                    toolId={toolId}
                    attrArray={attrArray}
                    isFavorited={favoritesData?.some(
                      (favoritedItem) => favoritedItem.SapNumber == toolId
                    )}
                    refetchFavoritesData={refetch}
                  />
                )
              )}
            </ToolComparisonContainerDesktop>

            {/* Mobile View */}
            <ToolComparisonContainerMobile>
              <Carousel
                options={{
                  type: 'loop',
                  perPage: 1,
                  gap: '1rem',
                  pagination: true,
                  arrows: true,
                  breakpoints: {
                    768: { perPage: 1 },
                  },
                }}
              >
                <Slide key={'attr'}>
                  {isLoading ? (
                    <SkeletonLoaderContainer>
                      <SkeletonRow height={550} width={200} count={1} />
                    </SkeletonLoaderContainer>
                  ) : (
                    <AttributeCard attrArray={attrArray} />
                  )}
                </Slide>
                {toolsArr.map((toolId) => (
                  <Slide key={toolId}>
                    {isLoading ? (
                      <SkeletonLoaderContainer>
                        <SkeletonRow height={550} width={200} count={1} />
                      </SkeletonLoaderContainer>
                    ) : (
                      <ToolCard
                        key={toolId}
                        firstTool={data}
                        toolId={toolId}
                        attrArray={attrArray}
                        isFavorited={favoritesData?.some(
                          (favoritedItem) => favoritedItem.SapNumber == toolId
                        )}
                        refetchFavoritesData={refetch}
                      />
                    )}
                  </Slide>
                ))}
              </Carousel>
            </ToolComparisonContainerMobile>
          </ToolComparisonContainer>
        </>
      )}
    </Container>
  );
};

export default index;
