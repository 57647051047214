import React, { createContext, useState } from 'react';

export const ReturningUserContext = createContext({
  isReturnUser: false,
  setReturningUser: () => {},
});

export const ReturningUserContextProvider = ({ children }) => {
  const [isReturnUser, setIsRetUser] = useState(false);

  const setReturningUser = (state) => {
    setIsRetUser(state);
  };

  return (
    <ReturningUserContext.Provider value={{ isReturnUser, setReturningUser }}>
      {children}
    </ReturningUserContext.Provider>
  );
};
