import React, { useState, useContext } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';

import { ErrorModalContext } from '../../Modals/ErrorModal';
import ErrorModal from '../../Modals/ErrorModal.jsx';
import { useOktaAuthContext } from '../../OktaAuthContext.js';

import { Plus, Minus } from 'react-feather';

import {
  addItemToFavorites,
  removeItemFromFavorites,
} from '../../backend/favorites.js';

const ToggleFavoritesButton = styled.button`
  display: flex;
  justify-content: end;
  align-items: center;

  margin-left: auto;
  border: none;
  background: none;
  cursor: pointer;
  font-family: Univers;
  width: 23%;
  margin-top: 10px;
  opacity: ${(props) => props.$isLoading && '0.5'};
`;

const ButtonText = styled.span`
  color: ${(props) =>
    props.$isFavorited ? 'var(--color-primary)' : '#117072'};
  font-size: 14px;
  font-weight: 600;
  padding-left: 2px;
  font-style: ${(props) => props.$isLoading && 'italic'};
`;

const FavoritesToggleButton = ({
  pagePath,
  sapNumber,
  isFavorited,
  refetchFavoritesData,
  className,
}) => {
  const [, setError] = useContext(ErrorModalContext);
  const { openModal } = useOktaAuthContext();

  const [
    isLoadingAddToFavoritesWithDelay,
    setIsLoadingAddToFavoritesWithDelay,
  ] = useState(false);
  const [
    isLoadingRemoveFromFavoritesWithDelay,
    setIsLoadingRemoveFromFavoritesWithDelay,
  ] = useState(false);

  const { mutate: addToFavoritesList, isLoading: isLoadingAddItemToFavorites } =
    useMutation((data) => addItemToFavorites(sapNumber.toString()), {
      onError: (error) => {
        setIsLoadingAddToFavoritesWithDelay(false);
        const errorCode = error.message.split('/');
        if (error.constructor.name === 'TypeError') {
          setError({
            title: 'Looks like something went wrong',
            message:
              'Network connection lost. Please check your network connection and try again.',
          });
        } else if (errorCode[1] === '401') {
          openModal(pagePath);
        } else {
          setError({
            title: 'Looks like something went wrong',
            message:
              'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
            errorCode: `#FA-2-${errorCode[1]}`,
          });
        }
      },
      onSuccess: () => {
        refetchFavoritesData().finally(() =>
          setIsLoadingAddToFavoritesWithDelay(false)
        );
      },
    });

  const {
    mutate: removeFromFavorites,
    isLoading: isLoadingRemoveItemsFromFavorites,
  } = useMutation((data) => removeItemFromFavorites(sapNumber.toString()), {
    onError: (error) => {
      setIsLoadingRemoveFromFavoritesWithDelay(false);
      const errorCode = error.message.split('/');
      if (error.constructor.name === 'TypeError') {
        setError({
          title: 'Looks like something went wrong',
          message:
            'Network connection lost. Please check your network connection and try again.',
        });
      } else if (errorCode[1] === '401') {
        openModal(pagePath);
      } else {
        setError({
          title: 'Looks like something went wrong',
          message:
            'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
          errorCode: `#FA-3-${errorCode[1]}`,
        });
      }
    },
    onSuccess: () => {
      refetchFavoritesData().finally(() =>
        setIsLoadingRemoveFromFavoritesWithDelay(false)
      );
    },
  });

  const handleAddToFavorites = () => {
    setIsLoadingAddToFavoritesWithDelay(true);
    addToFavoritesList();
  };

  const handleRemoveFromFavorites = () => {
    setIsLoadingRemoveFromFavoritesWithDelay(true);
    removeFromFavorites();
  };

  return (
    <>
      <ErrorModal />
      {!isFavorited ? (
        <ToggleFavoritesButton
          disabled={
            isLoadingAddItemToFavorites || isLoadingAddToFavoritesWithDelay
          }
          $isLoading={
            isLoadingAddItemToFavorites || isLoadingAddToFavoritesWithDelay
          }
          onClick={() => handleAddToFavorites()}
          className={className}
        >
          <Plus size={14} strokeWidth={4} color={'#007e83'} />
          <ButtonText
            $isLoading={
              isLoadingAddItemToFavorites || isLoadingAddToFavoritesWithDelay
            }
          >
            {isLoadingAddItemToFavorites || isLoadingAddToFavoritesWithDelay
              ? 'Adding to favorites...'
              : 'Add to favorites'}
          </ButtonText>
        </ToggleFavoritesButton>
      ) : (
        <ToggleFavoritesButton
          className={className}
          disabled={
            isLoadingRemoveItemsFromFavorites ||
            isLoadingRemoveFromFavoritesWithDelay
          }
          $isLoading={
            isLoadingRemoveItemsFromFavorites ||
            isLoadingRemoveFromFavoritesWithDelay
          }
          onClick={() => handleRemoveFromFavorites()}
        >
          <Minus size={14} strokeWidth={4} color={'var(--color-primary)'} />
          <ButtonText
            $isFavorited
            $isLoading={
              isLoadingRemoveItemsFromFavorites ||
              isLoadingRemoveFromFavoritesWithDelay
            }
          >
            {isLoadingRemoveItemsFromFavorites ||
            isLoadingRemoveFromFavoritesWithDelay
              ? 'Removing from favorites...'
              : 'Remove from favorites'}
          </ButtonText>
        </ToggleFavoritesButton>
      )}
    </>
  );
};

export default FavoritesToggleButton;
