import React from 'react';
import styled, { keyframes } from 'styled-components';
import SourceSetImage from './sourcesetimage.js';
import SpinnerImage1x from '../../../media/spinner/spinner.png';
import SpinnerImage2x from '../../../media/spinner/spinner@2x.png';
import SpinnerImage3x from '../../../media/spinner/spinner@3x.png';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SpinnerWrapper = styled.div`
  position: relative;
  width: 64px;
  height: 33px;
  align-self: center;
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;

  &.small {
    width: 32px;
    height: 32px;
    color: white;
  }

  &.extraSmall {
    width: 22px;
    height: 22px;
    color: white;
  }

  &.active img {
    animation: ${spin} 1s infinite linear;
  }

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &.compact.small {
    width: 26px;
    height: 26px;
  }

  &.compact.extraSmall {
    width: 22px;
    height: 22px;
  }
`;

const Spinner = (props) => {
  const { active, small, extraSmall, className } = props;

  const baseClassName = [
    small && 'small',
    extraSmall && 'extraSmall',
    active && 'active',
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <SpinnerWrapper className={`${baseClassName} ${className || ''}`}>
      <SourceSetImage
        alt="spinner"
        images={[SpinnerImage1x, SpinnerImage2x, SpinnerImage3x]}
      />
    </SpinnerWrapper>
  );
};

export default Spinner;
