import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import PageTitle from '../../shared/PageTitle';
import BackButton from '../../shared/BackButton';

import iOSInstructionsImage1 from '../../../media/images/instructions-ios-01.png';
import iOSInstructionsImage2 from '../../../media/images/instructions-ios-02.png';
import iOSInstructionsImage3 from '../../../media/images/instructions-ios-03.png';

import AndroidInstructionsImage1 from '../../../media/images/instructions-android-01.png';
import AndroidInstructionsImage2 from '../../../media/images/instructions-android-02.png';
import AndroidInstructionsImage3 from '../../../media/images/instructions-android-03.png';
import AndroidInstructionsImage4 from '../../../media/images/instructions-android-04.png';

const StyledContainer = styled.div`
  background-color: white;
`;

const MainContainer = styled.div`
  padding: 28px 96px 125px;
  font-family: Univers;

  & h2 {
    font-size: 26px;
    font-weight: 500;
    margin-top: 50px;
    color: var(--color-gray1);
  }

  @media (max-width: 900px) {
    padding: 18px 20px 0;
    & h2 {
      font-size: 20px;
      margin-top: 26px;
    }
  }
`;

const InstructionImage = styled.img`
  height: 400px;
  position: absolute;
  right: 30%;
  pointer-events: none;
  @media (max-width: 900px) {
    display: none;
  }
`;

const SmallInstructionImage = styled.img`
  height: 150px;
  position: absolute;
  right: 30%;
  pointer-events: none;
  @media (max-width: 900px) {
    display: none;
  }
`;

const InstructionSection = styled.section`
  display: flex;
  position: relative;
  margin-bottom: ${(props) => (props.$biggerMargin ? '320px' : '100px')};
  font-family: Univers;

  & h3 {
    font-size: 26px;
    font-weight: 300;
    margin: 0 0 16px;
    color: var(--color-gray1);
  }

  & h4 {
    font-size: 26px;
    margin: 0;
    color: var(--color-primary);
  }
  & p {
    font-size: 14px;
    line-height: 1.5;
    width: 460px;
    margin: 4px 0 24px;
    color: var(--color-gray1);
  }

  & ul {
    font-size: 14px;
    margin: 9px 0 24px;
    padding-left: 0px;
    color: var(--color-gray1);
  }

  & li {
    margin-left: 16px;
    line-height: 1.6;
  }

  @media (max-width: 900px) {
    margin-bottom: 30px;

    & h3 {
      font-size: 20px;
    }

    & h4 {
      font-size: 20px;
    }

    & p {
      width: fit-content;
      word-break: break-all;
    }
  }
`;

const InnerSection = styled.div`
  margin-left: 20px;
  @media (max-width: 900px) {
    margin-left: 0;
  }
`;

const NewTabLink = styled.a`
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  color: var(--color-dark-teal);
`;

const QuickAccessInstructionsPage = () => {
  const navigate = useNavigate();

  const handleBackToLanding = () => {
    navigate('/');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <StyledContainer>
        <div>
          <MainContainer>
            <BackButton text="Back to home" onClick={handleBackToLanding} />

            <PageTitle text="Icon on device instructions" />

            <h2 id="ios">iOS instructions</h2>

            <InstructionSection>
              <div>
                <h3>Step 1: Visit website</h3>

                <InnerSection>
                  <h4>URL</h4>
                  <p>
                    <NewTabLink
                      href="https://search.ecompletions.ienergy.halliburton.com"
                      target="_blank"
                    >
                      https://search.ecompletions.ienergy.halliburton.com
                    </NewTabLink>
                  </p>

                  <h4>Share tray</h4>
                  <p>
                    Once on the site, click the Share Tray at the bottom of the
                    screen.
                  </p>

                  <h4>Notes</h4>
                  <ul>
                    <li>Upon arrival, user will be prompted to login</li>
                    <li>
                      User must use the device they wish to store the quick
                      access icon
                    </li>
                    <li>
                      These instructions are for iOS devices (For Android,{' '}
                      <NewTabLink href="#android">click here</NewTabLink>)
                    </li>
                    <li>Browser must be Safari</li>
                  </ul>
                </InnerSection>
              </div>
              <InstructionImage
                src={iOSInstructionsImage1}
                alt="ios-screenshot-of-where-to-find-share-icon"
              />
            </InstructionSection>

            <InstructionSection $biggerMargin>
              <div>
                <h3>Step 2: Select &ldquo;Add to home screen&rdquo;</h3>

                <InnerSection>
                  <h4>Overview</h4>
                  <p>
                    Share Tray will open and user will need to scroll down the
                    list until they find the “Add to home screen” option.
                  </p>
                </InnerSection>
              </div>
              <InstructionImage
                src={iOSInstructionsImage2}
                alt="ios-screenshot-of-where-to-find-add-to-home-screen"
              />
            </InstructionSection>

            <InstructionSection>
              <div>
                <h3>Step 3: Confirm details</h3>

                <InnerSection>
                  <h4>Overview</h4>
                  <ul>
                    <li>A new window will pop up</li>
                    <li>
                      On this screen, the user can adjust the name if they see
                      fit
                    </li>
                    <li>Click “Add” at the top right hand corner</li>
                  </ul>
                </InnerSection>
              </div>
              <SmallInstructionImage
                src={iOSInstructionsImage3}
                alt="ios-screenshot-of-confirming-details"
              />
            </InstructionSection>

            <h2 id="android">Android instructions</h2>

            <InstructionSection>
              <div>
                <h3>Step 1: Visit website</h3>

                <InnerSection>
                  <h4>URL</h4>
                  <p>
                    <NewTabLink
                      href="https://search.ecompletions.ienergy.halliburton.com"
                      target="_blank"
                    >
                      https://search.ecompletions.ienergy.halliburton.com
                    </NewTabLink>
                  </p>

                  <h4>Share tray</h4>
                  <p>
                    Click the three line menu extension in the bottom right
                    corner.
                  </p>

                  <h4>Notes</h4>
                  <ul>
                    <li>Upon arrival, user will be prompted to login</li>
                    <li>
                      User must use the device they wish to store the quick
                      access icon
                    </li>
                    <li>
                      These instructions are for Android devices (For iOS,{' '}
                      <NewTabLink href="#ios">click here</NewTabLink>)
                    </li>
                  </ul>
                </InnerSection>
              </div>
              <InstructionImage
                src={AndroidInstructionsImage1}
                alt="android-screenshot-of-share-tray"
              />
            </InstructionSection>

            <InstructionSection $biggerMargin>
              <div>
                <h3>Step 2: Select &ldquo;Add page to&rdquo;</h3>

                <InnerSection>
                  <h4>Overview</h4>
                  <p>
                    Menu will open and user will need to tap the &ldquo;Add page
                    to &rdquo; option. Note: On some devices, this may be
                    referred to as &ldquo;Install App.&rdquo;
                  </p>
                </InnerSection>
              </div>
              <InstructionImage
                src={AndroidInstructionsImage2}
                alt="android-screenshot-of-add-to-home-screen"
              />
            </InstructionSection>

            <InstructionSection $biggerMargin>
              <div>
                <h3>Step 3: Select &ldquo;Home screen&rdquo;</h3>

                <InnerSection>
                  <h4>Overview</h4>
                  <ul>
                    <li>
                      Menu will open further to where the current webpage needs
                      to be added to
                    </li>
                    <li>
                      {' '}
                      User will need to tap the &ldquo;Home screen&ldquo; button
                    </li>
                  </ul>
                </InnerSection>
              </div>
              <InstructionImage
                src={AndroidInstructionsImage3}
                alt="android-screenshot-of-detail-confirmation"
              />
            </InstructionSection>

            <InstructionSection>
              <div>
                <h3>Step 4: Confirm details</h3>

                <InnerSection>
                  <h4>Overview</h4>
                  <ul>
                    <li>A new window will pop up</li>
                    <li>
                      User will need to click &ldquo;Add&rdquo; in the bottom
                      right hand corner.
                      <br />
                      Note: on some devices, this may be displayed as
                      &ldquo;Install.&rdquo;
                    </li>
                  </ul>
                </InnerSection>
              </div>
              <InstructionImage
                src={AndroidInstructionsImage4}
                alt="android-screenshot-of-detail-confirmation"
              />
            </InstructionSection>
          </MainContainer>
        </div>
      </StyledContainer>
    </div>
  );
};

export default QuickAccessInstructionsPage;
