import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { appInsights } from '../../appInsights.js';
import styled from 'styled-components';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { ErrorModalContext } from '../../Modals/ErrorModal';
import ErrorModal from '../../Modals/ErrorModal.jsx';

import { fetchPreviousQuotes } from '../../backend/previous_quotes.js';
import PreviousQuotesCluster from './PreviousQuotesCluster.jsx';

import { useOktaAuthContext } from '../../OktaAuthContext.js';
import { useOktaAuth } from '@okta/okta-react';
import DataFetchErrorPlaceholder from '../../shared/DataFetchErrorPlaceholder.jsx';

const SkeletonLoaderContainer = styled.div`
  margin-left: 8px;
  margin-right: 12px;

  @media (max-width: 1024px) {
    margin: 0;
    padding-left: 5%;
    padding-right: 5%;
  }
`;

const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto;
`;

const SkeletonRow = styled(Skeleton)`
  margin-bottom: 10px;
  // border-radius: 14px;
`;

const PreviousQuotesContainer = styled.div`
  background-color: var(--color-gray6);
  width: 100%;
  min-height: calc(100vh - 150px);
  padding-top: 12px;
  padding-bottom: 20px;

  @media (max-width: 1024px) {
    padding: 0;
    background-color: #fff;
  }
`;

const PreviousQuotesSubContainer = styled.div`
  min-height: calc(100vh - 300px);
  display: flex;
  flex-direction: column;
  min-height: 500px;
  font-family: Univers;
  margin-left: 112px;
  margin-right: 112px;
  background-color: white;
  // border-radius: 10px;
  pointer-events: ${(props) => (props.$isDisabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.$isDisabled ? '0.4' : '1')};

  @media (max-width: 1024px) {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
`;

const PreviousQuotesTitle = styled.div`
  color: var(--color-gray1);
  font-weight: 500;
  font-size: 28px;
  margin: 20px 0px 18px 20px;
  @media (max-width: 1024px) {
    font-size: 22px;
    margin: 14px 0px 12px 5%;
    width: 100%;
  }
`;

const EmptyStateContainer = styled.div`
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-gray3);
  font-size: 28px;
  font-weight: 500;
  text-align: center;
`;

const PreviousQuotes = () => {
  const [, setError] = useContext(ErrorModalContext);
  const { openModal } = useOktaAuthContext();
  const { authState } = useOktaAuth();
  const [showFetchDataErrorScreen, setShowFetchDataErrorScreen] =
    useState(false);

  useEffect(() => {
    !authState?.isAuthenticated && openModal('/previous-requests');
  }, [authState?.isAuthenticated]);

  useEffect(() => {
    const trackViewChange = () => {
      appInsights.trackPageView({ name: location.pathname });
    };
    trackViewChange();
  }, [location.pathname]);

  useEffect(() => {
    document.title = 'Previous requests';

    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = "User's previous quote requests";

    document.head.appendChild(metaDescription);

    return () => {
      document.title = '';
      document.head.removeChild(metaDescription);
    };
  }, []);

  const {
    data: fetchedPreviousQuotesData,
    isLoading: isPreviousQuotesDataLoading,
  } = useQuery('fetchPreviousQuotes', fetchPreviousQuotes, {
    onError: (error) => {
      const errorCode = error.message.split('/');
      if (error.constructor.name === 'TypeError') {
        setError({
          title: 'Looks like something went wrong',
          message:
            'Network connection lost. Please check your network connection and try again.',
        });
        setShowFetchDataErrorScreen(true);
      } else if (errorCode[1] !== '401') {
        setError({
          title: 'Looks like something went wrong',
          message:
            'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
          errorCode: `#PO-1-${errorCode[1]}`,
        });
      }
    },
  });

  return (
    <PreviousQuotesContainer>
      <PreviousQuotesSubContainer>
        {showFetchDataErrorScreen ? (
          <CenterDiv>
            <DataFetchErrorPlaceholder />
          </CenterDiv>
        ) : (
          <>
            <PreviousQuotesTitle>Previous requests</PreviousQuotesTitle>
            {isPreviousQuotesDataLoading ? (
              <SkeletonLoaderContainer>
                <SkeletonRow height={50} width={'100%'} count={7} />
              </SkeletonLoaderContainer>
            ) : (
              <>
                {' '}
                {fetchedPreviousQuotesData?.length > 0 ? (
                  <>
                    {fetchedPreviousQuotesData
                      .sort(
                        (a, b) => new Date(b.DateTime) - new Date(a.DateTime)
                      )
                      .map((quote) => (
                        <PreviousQuotesCluster key={quote.id} data={quote} />
                      ))}
                  </>
                ) : (
                  <EmptyStateContainer>
                    {authState?.isAuthenticated
                      ? 'No requests available'
                      : 'Please sign into your account to access this feature'}{' '}
                  </EmptyStateContainer>
                )}
              </>
            )}{' '}
          </>
        )}
      </PreviousQuotesSubContainer>

      <ErrorModal />
    </PreviousQuotesContainer>
  );
};

export default PreviousQuotes;
