import React, { useEffect, useState, useContext, useRef } from 'react';
import styled from 'styled-components';
import SearchContext from '../../SearchContext';

import RevealCategory from '../RevealCategory';

const FiltersContainer = styled.div`
  grid-template-rows: repeat(3, 1fr);
  grid-auto-rows: 28px;
  margin: 30px 47px 30px 40px;
  font-size: 14px;
`;
const SizeFilterRow = styled.div`
  margin-bottom: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SizeFilterLabel = styled.label`
  width: 220px;
`;

const SizeFilterLabelTo = styled.label`
  padding: 0px 7px;
`;
const SizeFilterLabelMeasure = styled.label`
  padding: 0px 7px;
  color: var(--color-primary);
`;

const SizeFilterInput = styled.input`
  width: 100%;
  height: 25px;
`;

const Size = ({setApplyFiltersEnabled}) => {
  const {
    onChangeSizeMinIdValue,
    onChangeSizeMaxIdValue,
    onChangeSizeMinOdValue,
    onChangeSizeMaxOdValue,
    onChangeSizeMinLength,
    onChangeSizeMaxLength,
    sizeMinIdValue,
    sizeMaxIdValue,
    sizeMinOdValue,
    sizeMaxOdValue,
    sizeMinLengthValue,
    sizeMaxLengthValue,
    filterApplyClicked,
  } = useContext(SearchContext);

  const [isRowExpanded, setIsRowExpanded] = useState(false);
  const [minIdValue, setMinIdValue] = useState(sizeMinIdValue);
  const [maxIdValue, setMaxIdValue] = useState(sizeMaxIdValue);

  const [minOdValue, setMinOdValue] = useState(sizeMinOdValue);
  const [maxOdValue, setMaxOdValue] = useState(sizeMaxOdValue);

  const [minLengthValue, setMinLengthValue] = useState(sizeMinLengthValue);
  const [maxLengthValue, setMaxLengthValue] = useState(sizeMaxLengthValue);

  const [idFilterCount, setIdFilterCount] = useState(0);
  const [odFilterCount, setOdFilterCount] = useState(0);
  const [lengthFilterCount, setLengthFilterCount] = useState(0);

  const [sumOfFilterCounts, setSumOfFilterCounts] = useState(0);

  useEffect(() => {
    setSumOfFilterCounts(idFilterCount + odFilterCount + lengthFilterCount);
  }, [idFilterCount, odFilterCount, lengthFilterCount]);

  const handleRowExpanded = (expanded) => {
    setIsRowExpanded(expanded);
  };

  const handleValueChange = (setValue, onChange, value) => {
    setValue(value);
    setApplyFiltersEnabled(true);
  };

  const handleMinIdValueChange = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^\d.]/g, '');
    handleValueChange(setMinIdValue, onChangeSizeMinIdValue, numericValue);
  };

  const handleMaxIdValueChange = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^\d.]/g, '');
    handleValueChange(setMaxIdValue, onChangeSizeMaxIdValue, numericValue);
  };

  const handleMinOdValueChange = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^\d.]/g, '');
    handleValueChange(setMinOdValue, onChangeSizeMinOdValue, numericValue);
  };

  const handleMaxOdValueChange = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^\d.]/g, '');
    handleValueChange(setMaxOdValue, onChangeSizeMaxOdValue, numericValue);
  };

  const handleMinLengthValueChange = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^\d.]/g, '');
    handleValueChange(setMinLengthValue, onChangeSizeMinLength, numericValue);
  };

  const handleMaxLengthValueChange = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^\d.]/g, '');
    handleValueChange(setMaxLengthValue, onChangeSizeMaxLength, numericValue);
  };

  useEffect(() => {
    const minValuePresent = parseInt(minIdValue) >= 0;
    const maxValuePresent = parseInt(maxIdValue) >= 0;
    if (minValuePresent || maxValuePresent) {
      setIdFilterCount(1);
    } else {
      setIdFilterCount(0);
    }
  }, [minIdValue, maxIdValue]);

  useEffect(() => {
    const minValuePresent = parseInt(minOdValue) >= 0;
    const maxValuePresent = parseInt(maxOdValue) >= 0;
    if (minValuePresent || maxValuePresent) {
      setOdFilterCount(1);
    } else {
      setOdFilterCount(0);
    }
  }, [minOdValue, maxOdValue]);

  useEffect(() => {
    const minValuePresent = parseInt(minLengthValue) >= 0;
    const maxValuePresent = parseInt(maxLengthValue) >= 0;
    if (minValuePresent || maxValuePresent) {
      setLengthFilterCount(1);
    } else {
      setLengthFilterCount(0);
    }
  }, [minLengthValue, maxLengthValue]);

  useEffect(() => {
    if (filterApplyClicked) {
      onChangeSizeMinIdValue(minIdValue);
      onChangeSizeMaxIdValue(maxIdValue);
      onChangeSizeMinOdValue(minOdValue);
      onChangeSizeMaxOdValue(maxOdValue);
      onChangeSizeMinLength(minLengthValue);
      onChangeSizeMaxLength(maxLengthValue);
    }
  }, [filterApplyClicked]);

  return (
    <>
      <RevealCategory
        name={'Size'}
        onIsRowExpanded={handleRowExpanded}
        filterCount={sumOfFilterCounts}
      />
      {isRowExpanded && (
        <FiltersContainer>
          <SizeFilterRow>
            <SizeFilterLabel>ID</SizeFilterLabel>
            <SizeFilterInput
              type="text"
              pattern="^\d+(?:\.\d+)?$"
              placeholder="Min"
              value={minIdValue}
              onChange={handleMinIdValueChange}
            />
            <SizeFilterLabelTo>to</SizeFilterLabelTo>
            <SizeFilterInput
              type="text"
              pattern="^\d+(?:\.\d+)?$"
              placeholder="Max"
              value={maxIdValue}
              onChange={handleMaxIdValueChange}
            />
            <SizeFilterLabelMeasure>in</SizeFilterLabelMeasure>
          </SizeFilterRow>
          <SizeFilterRow>
            <SizeFilterLabel>OD</SizeFilterLabel>
            <SizeFilterInput
              type="text"
              pattern="^\d+(?:\.\d+)?$"
              placeholder="Min"
              value={minOdValue}
              onChange={handleMinOdValueChange}
            />

            <SizeFilterLabelTo>to</SizeFilterLabelTo>
            <SizeFilterInput
              type="text"
              pattern="^\d+(?:\.\d+)?$"
              placeholder="Max"
              value={maxOdValue}
              onChange={handleMaxOdValueChange}
            />
            <SizeFilterLabelMeasure>in</SizeFilterLabelMeasure>
          </SizeFilterRow>
          <SizeFilterRow>
            <SizeFilterLabel>Length</SizeFilterLabel>
            <SizeFilterInput
              type="text"
              pattern="^\d+(?:\.\d+)?$"
              placeholder="Min"
              value={minLengthValue}
              onChange={handleMinLengthValueChange}
            />

            <SizeFilterLabelTo>to</SizeFilterLabelTo>
            <SizeFilterInput
              type="text"
              pattern="^\d+(?:\.\d+)?$"
              placeholder="Max"
              value={maxLengthValue}
              onChange={handleMaxLengthValueChange}
            />
            <SizeFilterLabelMeasure>ft</SizeFilterLabelMeasure>
          </SizeFilterRow>
        </FiltersContainer>
      )}
    </>
  );
};

export default Size;
