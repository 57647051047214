import React, { useState } from 'react';
import styled from 'styled-components';

import { ChevronRight } from 'react-feather';

const RevealRow = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: none;
  border: none;
  height: 57px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-gray4);
  padding: 0;
  padding-right: 2px;
  cursor: pointer;
  text-transform: capitalize;

  // &:first-letter {
  //   text-transform: lowercase;
  // }

  @media (max-width: 900px) {
    height: 50px;
    border-bottom: 1px solid var(--color-gray3);
    padding-right: 15px;
    padding-left: 13px;
  }
`;

const CategoryNameContainer = styled.div`
  display: flex;
  align-items: center;
`;
const CategoryName = styled.div`
  font-family: Univers;
  font-size: 16px;
  font-weight: 500;
  color: var(--color-gray1);
`;

const StyledChevronUp = styled(ChevronRight)`
  display: inline-block;
  cursor: pointer;

  transform: ${(props) =>
    props.$isRowExpanded ? 'rotate(90deg)' : 'rotate(0)'};
  transition: transform 0.3s ease;
`;

const FilterCountCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  background-color: var(--color-gray1);
  border-radius: 100%;
  color: white;
  font-size: 14px;
  margin-right: 5px;
`;

const RevealCategory = ({ name, onIsRowExpanded, filterCount }) => {
  const [isRowExpanded, setIsRowExpanded] = useState(false);

  const handleExpandRow = () => {
    setIsRowExpanded((prev) => !prev);
    onIsRowExpanded((prev) => !prev);
  };

  return (
    <>
      <RevealRow type="button" onClick={handleExpandRow}>
        <CategoryNameContainer>
          {filterCount > 0 && (
            <FilterCountCircle>{filterCount}</FilterCountCircle>
          )}
          <CategoryName>{name.toLowerCase()} </CategoryName>{' '}
        </CategoryNameContainer>

        <StyledChevronUp
          $isRowExpanded={isRowExpanded}
          height={30}
          width={30}
          strokeWidth={1}
        />
      </RevealRow>
    </>
  );
};

export default RevealCategory;
