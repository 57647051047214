import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { oktaAuth } from '../services/okta-service';
import DOMPurify from 'dompurify';

import { ErrorModalContext } from '../Modals/ErrorModal';

/* 
The app should never route to this page on it's own. Simply a redirect to handle the token.

This page is here for the okta call back. When utilizing an IDPS using okta sign
in widget it will redirect back to this page to handle the token.
*/

const LoginPage = () => {
  const navigate = useNavigate();
  const [error, setError] = useContext(ErrorModalContext);

  useEffect(() => {
    const handleLogin = async () => {
      try {
        if (oktaAuth.token.isLoginRedirect()) {
          const tokenResponse = await oktaAuth.token.parseFromUrl();
          const tokens = tokenResponse.tokens;
          oktaAuth.tokenManager.setTokens(tokens);

          if (tokens.idToken && tokens.accessToken) {
            await oktaAuth.authStateManager.updateAuthState();
          }

          const navParams = sessionStorage.getItem('preAuthPath');
          const sanitizedNavParams = DOMPurify.sanitize(navParams);

          const allowedPaths = [
            '/',
            '/login',
            '/logout',
            '/shopping-cart',
            '/favorites',
            '/previous-requests',
            '/browse',
            '/instructions',
            '/search',
            '/search/tool-comparison',
            '/tool/:id',
          ];

          const isAllowedPath = (path) => {
            if (typeof path !== 'string' || !path.trim()) {
              return false;
            }
            if (allowedPaths.includes(path)) {
              return true;
            }
            for (let allowedPath of allowedPaths) {
              if (allowedPath.includes(':')) {
                const regex = new RegExp(
                  `^${allowedPath.replace(/:\w+/g, '[^/]+')}$`
                );
                if (regex.test(path)) {
                  return true;
                }
              }
            }
            return false;
          };

          const safeNavParams = isAllowedPath(sanitizedNavParams)
            ? sanitizedNavParams
            : '/';
          if (safeNavParams !== sanitizedNavParams) {
            console.warn(
              'Attempted to navigate to an unsafe path:',
              sanitizedNavParams
            );
          }
          navigate(safeNavParams, { replace: true });
          return true;
        }
      } catch (err) {
        console.error(err);
        setError({
          title: 'Authentication Error',
          message: err.message,
        });
        oktaAuth.closeSession();
        navigate('/');
      }

      return false;
    };

    handleLogin().then((result) => (!result ? navigate('/') : null));
  }, [navigate, setError]);

  return <div>Logging in...</div>;
};

export default LoginPage;
