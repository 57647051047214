import React from 'react';
import styled from 'styled-components';

const AttributesList = styled.ul`
  font-family: Univers;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  flex: 1;
  list-style: none;
  width: 200px;
  min-height: 370px;
  font-size: 18px;
  border: 1px solid #000000;
  padding-inline-start: 0; /* For Firefox and others */
  padding-left: 0; /* For Chrome */
  margin: 0;
  overflow: hidden;
`;

const OddListItem = styled.li`
  display: flex;
  text-overflow: ellipsis;
  align-items: center;
  justify-content: left;
  width: 100%;
  min-height: 27px;
  background: #ffffff;
  color: #2d3a4d;
  border-bottom: ${(props) => (props.$isLast ? '' : '1px solid #000000')};
  font-size: 16px;
  padding: 0 5px 0 25px;

  @media (max-width: 1024px) {
    /* Tablet view */
    width: 100%;
  }
`;

const EvenListItem = styled.li`
  display: flex;
  text-overflow: ellipsis;
  align-items: center;
  justify-content: left;
  width: 100%;
  min-height: 27px;
  background: #e4e9ed;
  color: #2d3a4d;
  border-bottom: ${(props) => (props.$isLast ? '' : '1px solid #000000')};
  font-size: 16px;
  padding: 0 5px 0 25px;

  @media (max-width: 1024px) {
    /* Tablet view */
    width: 100%;
  }
`;

const Heading = styled.li`
  background: #43494e;
  text-overflow: ellipsis;
  color: white;
  min-height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-family: Univers;
  font-size: 16px;
  font-weight: 500;
`;

const EllipsisOverflow = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AttributeCard = ({ attrArray }) => {
  return (
    <AttributesList>
      <Heading>Attributes</Heading>
      {attrArray ? (
        attrArray.slice(0, 15).map((attr, index) => {
          return index % 2 !== 0 ? (
            <EvenListItem key={index} $isLast={index === 14}>
              <EllipsisOverflow>{attr.DisplayName}</EllipsisOverflow>
            </EvenListItem>
          ) : (
            <OddListItem key={index} $isLast={index === 14}>
              <EllipsisOverflow>{attr.DisplayName}</EllipsisOverflow>
            </OddListItem>
          );
        })
      ) : (
        <h1>No reference tool</h1>
      )}
    </AttributesList>
  );
};

export default AttributeCard;
