import React, { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useQuery, useMutation } from 'react-query';
import { appInsights } from '../../appInsights.js';
import styled from 'styled-components';
import { useOktaAuthContext } from '../../OktaAuthContext.js';

import SearchContext from './SearchContext.js';
import { ErrorModalContext } from '../../Modals/ErrorModal';
import ErrorModal from '../../Modals/ErrorModal.jsx';

import FilterPane from './FilterPane/index.jsx';
import SearchResults from './SearchResults/index.jsx';

import { searchWithinClasses } from '../../backend/search.js';

import { filterTools } from '../../backend/filter.js';

const StyledContainer = styled.div`
  background-color: #efefef;
  width: 100%;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  min-height: 100vh;
`;

const FilterPaneContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 40%;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const MobileFilterPane = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  z-index: 99;
`;

const MobileInnerFilterContainer = styled.div`
  height: 100%;
  position: absolute;
  right: 0;
  width: 74%;
  max-width: 450px;
  height: 100%;
  box-sizing: border-box;
  background-color: #e6e9ed;
  transition: transform 0.5s ease;
  transform: translateX(0);
  ${(props) => props.isSliding && 'transform: translateX(-100%);'}
`;

const SearchResultsContainer = styled.div`
  flex: 60%;
  margin-top: 18px;
  max-width: 70%;
  @media (max-width: 1024px) {
    flex: 100%;
    padding: 0 33px 0 20px;
    margin: 0;
    max-width: 90%;
  }
  background-color: white;
  padding: 0px 20px;
  height: 100%;
`;

const SearchPageContainer = () => {
  const { authState } = useOktaAuth();

  const [filterToolData, setFilterToolData] = useState(null);
  const [sumOfResults, setSumOfResults] = useState(0);
  const [calculatedSkip, setCalculatedSkip] = useState(0);
  const [rangeStart, setRangeStart] = useState(1);
  const [rangeEnd, setRangeEnd] = useState(
    (data || filterToolData) && Math.min(resultsPerPageFilter, sumOfResults)
  );
  const [leafClassesForFilter, setLeafClassesForFilter] = useState([]);
  const [showMobileFilterPane, setShowMobileFilterPane] = useState(false);
  const [mobileFilterRowsDisabled, setMobileFilterRowsDisabled] =
    useState(true);
  const [isLeaf, setIsLeaf] = useState(false);
  const [backToTopClicked, setBackToTopClicked] = useState(false);
  const [applyClicked, setApplyClicked] = useState(false);
  const [filterApplyClicked, setFilterApplyClicked] = useState(false);

  const [, setError] = useContext(ErrorModalContext);
  const { openModal, isOpen } = useOktaAuthContext();

  const authToken = authState?.accessToken?.accessToken;

  const login = async () => {
    openModal(
      `/search?term=${searchTerm}&resultsPerPage=${resultsPerPageFilter}&paginatedRange=${paginatedRange}&selectedClass=${selectedClass}&sizeMinIdValue=${sizeMinIdValue}&sizeMaxIdValue=${sizeMaxIdValue}&sizeMinOdValue=${sizeMinOdValue}&sizeMaxOdValue=${sizeMaxOdValue}&sizeMinLengthValue=${sizeMinLengthValue}&sizeMaxLengthValue=${sizeMaxLengthValue}`
    );
  };

  //! START OF API CALLS

  // POST FOR FILTER
  const {
    mutate: mutateFilter,
    isLoading: isLoadingFilteredSearch,
    error: filterError,
  } = useMutation(
    (data) =>
      filterTools(
        selectedClass,
        dynamicFilterCategories,
        sizeMinIdValue,
        sizeMaxIdValue,
        sizeMinOdValue,
        sizeMaxOdValue,
        sizeMinLengthValue,
        sizeMaxLengthValue,
        searchTerm,
        resultsPerPageFilter,
        calculatedSkip,
        browseOptions.join('|')
      ),

    {
      onSuccess: (responseData) => {
        setFilterToolData(responseData);
        setApplyClicked(false);
      },
      onError: (error) => {
        const errorCode = error.message.split('/');
        if (error.constructor.name === 'TypeError') {
          setError({
            title: 'Looks like something went wrong',
            message:
              'Network connection lost. Please check your network connection and try again.',
          });
        } else if (errorCode[1] === '401') {
          login();
        } else {
          setError({
            title: 'Looks like something went wrong',
            message:
              'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
            errorCode: `#FI-2-${errorCode[1]}`,
          });
        }
      },
    }
  );

  useEffect(() => {
    sessionStorage.setItem('hasSearchHistory', 'true');
  });

  //! END OF API CALLS

  // ! START URL PARAMS STATE
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const trackViewChange = () => {
      appInsights.trackPageView({ name: location.pathname });
    };
    trackViewChange();
  }, [location.pathname]);

  const searchParams = new URLSearchParams(location.search);
  // Params variables
  const [searchTerm, setSearchTerm] = useState(searchParams.get('term') || '');

  const [resultsPerPageFilter, setResultsPerPageFilter] = useState(
    searchParams.get('resultsPerPage')
      ? parseInt(searchParams.get('resultsPerPage'))
      : 10
  );
  const [paginatedRange, setPaginatedRange] = useState(
    searchParams.get('paginatedRange')
      ? parseInt(searchParams.get('paginatedRange'))
      : 1
  );
  const [selectedClass, setSelectedClass] = useState(
    searchParams.get('selectedClass') || ''
  );
  const [sizeMinIdValue, setSizeMinIdValue] = useState(
    searchParams.get('sizeMinIdValue') || ''
  );
  const [sizeMaxIdValue, setSizeMaxIdValue] = useState(
    searchParams.get('sizeMaxIdValue') || ''
  );
  const [sizeMinOdValue, setSizeMinOdValue] = useState(
    searchParams.get('sizeMinOdValue') || ''
  );
  const [sizeMaxOdValue, setSizeMaxOdValue] = useState(
    searchParams.get('sizeMaxOdValue') || ''
  );
  const [sizeMinLengthValue, setSizeMinLengthValue] = useState(
    searchParams.get('sizeMinLengthValue') || ''
  );
  const [sizeMaxLengthValue, setSizeMaxLengthValue] = useState(
    searchParams.get('sizeMaxLengthValue') || ''
  );

  const staticKeys = [
    'term',
    'resultsPerPage',
    'paginatedRange',
    'selectedClass',
    'sizeMinIdValue',
    'sizeMaxIdValue',
    'sizeMinOdValue',
    'sizeMaxOdValue',
    'sizeMinLengthValue',
    'sizeMaxLengthValue',
    'browseOptions',
  ];

  const [dynamicFilterCategories, setDynamicFilterCategories] = useState(() => {
    const dynamicFilters = [];

    for (const key of searchParams.keys()) {
      if (!staticKeys.includes(key)) {
        const filterValues =
          searchParams.get(key)?.split('|').map(decodeURIComponent) || [];
        dynamicFilters.push({
          name: decodeURIComponent(key),
          filterValues,
        });
      }
    }

    return dynamicFilters;
  });

  const [browseOptions, setBrowseOptions] = useState(
    searchParams.get('browseOptions')
      ? searchParams.get('browseOptions').split(',')
      : []
  );

  // This effect handles changes to results per page in relation to paginatedRange
  useEffect(() => {
    setRangeEnd(Math.min(paginatedRange * resultsPerPageFilter, sumOfResults));
    setRangeStart(
      (paginatedRange - 1) * resultsPerPageFilter + 1,
      sumOfResults
    );

    if (
      (data && totalPages && filterToolData) ||
      (browseOptions.length && isLeaf && filterToolData && sumOfResults) //! DO WE NEED ISLEAF HERE
    ) {
      setCalculatedSkip(
        Math.max(0, (paginatedRange - 1) * resultsPerPageFilter)
      );
      setRangeStart(
        (paginatedRange - 1) * resultsPerPageFilter + 1,
        sumOfResults
      );

      // Calculate the adjusted page and update skip if needed
      const newPage = Math.ceil(calculatedSkip / resultsPerPageFilter) + 1;
      const totalPages = Math.ceil(sumOfResults / resultsPerPageFilter);
      let adjustedPage = newPage;

      // Adjust the page if it exceeds total pages
      if (newPage > totalPages) {
        adjustedPage = totalPages;
        const newSkip = Math.max(0, (adjustedPage - 1) * resultsPerPageFilter);
        setCalculatedSkip(newSkip);
        setPaginatedRange(adjustedPage);
        // Calculate the new range start and end based on adjusted page and results per page
        const newRangeStart = Math.min(
          (adjustedPage - 1) * resultsPerPageFilter + 1,
          sumOfResults
        );
        const newRangeEnd = Math.min(
          adjustedPage * resultsPerPageFilter,
          sumOfResults
        );

        // Update the range start and end
        setRangeStart(newRangeStart);
        setRangeEnd(newRangeEnd);
      }
    }
  }, [
    resultsPerPageFilter,
    calculatedSkip,
    sumOfResults,
    data,
    filterToolData,
    paginatedRange,
    browseOptions,
  ]);

  const totalPages = Math.ceil(sumOfResults / resultsPerPageFilter);
  const rangePropertyFilters = null;
  const propertyFilters = null;

  useEffect(() => {
    const searchParams = new URLSearchParams();
    searchParams.set('term', searchTerm);
    searchParams.set('resultsPerPage', resultsPerPageFilter);
    searchParams.set('paginatedRange', paginatedRange);
    searchParams.set('selectedClass', selectedClass);
    searchParams.set('sizeMinIdValue', sizeMinIdValue);
    searchParams.set('sizeMaxIdValue', sizeMaxIdValue);
    searchParams.set('sizeMinOdValue', sizeMinOdValue);
    searchParams.set('sizeMaxOdValue', sizeMaxOdValue);
    searchParams.set('sizeMinLengthValue', sizeMinLengthValue);
    searchParams.set('sizeMaxLengthValue', sizeMaxLengthValue);
    searchParams.set('browseOptions', browseOptions);

    dynamicFilterCategories.forEach((filter) => {
      const urlParamName = encodeURIComponent(filter.name);
      const filterValues = filter.filterValues
        .map((value) => encodeURIComponent(value))
        .join('|');

      if (filterValues.length > 0) {
        searchParams.set(urlParamName, filterValues);
      }
    });

    const login = async () => {
      openModal(
        `/search?term=${searchTerm}&resultsPerPage=${resultsPerPageFilter}&paginatedRange=${paginatedRange}&selectedClass=${selectedClass}&sizeMinIdValue=${sizeMinIdValue}&sizeMaxIdValue=${sizeMaxIdValue}&sizeMinOdValue=${sizeMinOdValue}&sizeMaxOdValue=${sizeMaxOdValue}&sizeMinLengthValue=${sizeMinLengthValue}&sizeMaxLengthValue=${sizeMaxLengthValue}`
      );
    };

    navigate({ search: searchParams.toString() }, { replace: true });
    if (authToken) {
      setBackToTopClicked(false);
      mutateFilter(
        selectedClass,
        dynamicFilterCategories,
        sizeMinIdValue,
        sizeMaxIdValue,
        sizeMinOdValue,
        sizeMaxOdValue,
        sizeMinLengthValue,
        sizeMaxLengthValue,
        searchTerm,
        resultsPerPageFilter,
        calculatedSkip,
        browseOptions.join('|')
      );
    } else if (authState && !authState.isAuthenticated) {
      login();
    }
  }, [
    searchTerm,
    paginatedRange,
    sizeMinIdValue,
    sizeMaxIdValue,
    sizeMinOdValue,
    sizeMaxOdValue,
    sizeMinLengthValue,
    sizeMaxLengthValue,
    authToken,
    authState,
    calculatedSkip,
    resultsPerPageFilter,
    // duplicate call being made due to this dep change in handleDynamicFilterChange
    dynamicFilterCategories,
  ]);

  // FETCH
  const {
    data,
    isLoading: isLoadingSearchWithinClasses,
    error,
    refetch,
  } = useQuery(
    ['searchClass', searchTerm],
    () => searchWithinClasses(searchTerm),
    {
      staleTime: Infinity,
      enabled: searchTerm !== '',

      onError: (error) => {
        const errorCode = error.message.split('/');
        setFilterToolData([]);

        if (error.constructor.name === 'TypeError') {
          setError({
            title: 'Looks like something went wrong',
            message:
              'Network connection lost. Please check your network connection and try again.',
          });
        } else if (errorCode[1] === '401') {
          login();
        } else {
          setError({
            title: 'Looks like something went wrong',
            message:
              'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
            errorCode: `#CLKW-1-${errorCode[1]}`,
          });
        }
      },
    }
  );

  useEffect(() => {
    if (filterToolData) {
      setSumOfResults(filterToolData.TotalResultCount);
    }
  }, [filterToolData]);

  const handleClearAll = () => {
    setSizeMinIdValue('');
    setSizeMaxIdValue('');
    setSizeMinOdValue('');
    setSizeMaxOdValue('');
    setSizeMinLengthValue('');
    setSizeMaxLengthValue('');
    setPaginatedRange(1);
    setResultsPerPageFilter(10);
    setCalculatedSkip(0);
    setRangeStart(1);
    setRangeEnd(
      (data || filterToolData) && Math.min(resultsPerPageFilter, sumOfResults)
    );
    setDynamicFilterCategories([]);
  };

  const handleSearchTermChange = (query) => {
    handleClearAll();
    setSearchTerm(query);
  };

  const handleRangeFilterChange = (resultsPerPage) => {
    setResultsPerPageFilter(resultsPerPage);
  };

  const handlePaginatedRangeChange = (number) => {
    const totalPages = Math.ceil(sumOfResults / resultsPerPageFilter);
    let newPage = Math.min(number, totalPages);
    const newSkip = Math.max(0, (newPage - 1) * resultsPerPageFilter);

    const newRangeStart = Math.min(
      (newPage - 1) * resultsPerPageFilter + 1,
      sumOfResults
    );
    const newRangeEnd = Math.min(newPage * resultsPerPageFilter, sumOfResults);

    if (newPage > totalPages) {
      newPage = totalPages;
    }

    setCalculatedSkip(newSkip);
    setPaginatedRange(newPage);
    setRangeStart(newRangeStart);
    setRangeEnd(newRangeEnd);
  };

  const handleSelectClass = (option) => {
    setSelectedClass(option);

    searchParams.set('selectedClass', option);
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  const handleSizeMinIdValue = (minId) => {
    setSizeMinIdValue(minId);
  };
  const handleSizeMaxIdValue = (maxId) => {
    setSizeMaxIdValue(maxId);
  };
  const handleSizeMinOdValue = (minOd) => {
    setSizeMinOdValue(minOd);
  };
  const handleSizeMaxOdValue = (maxOd) => {
    setSizeMaxOdValue(maxOd);
  };
  const handleSizeMinLength = (minLength) => {
    setSizeMinLengthValue(minLength);
  };
  const handleSizeMaxLength = (maxLength) => {
    setSizeMaxLengthValue(maxLength);
  };
  const handleDynamicFilterUpdate = (
    dynamicFilterName,
    updatedFilterValues
  ) => {
    setDynamicFilterCategories((prevFilters) => {
      if (updatedFilterValues.length === 0) {
        const newFilters = prevFilters.filter(
          (filter) => filter.name !== dynamicFilterName
        );

        // Return the same array reference if no changes are made
        if (newFilters.length === prevFilters.length) {
          return prevFilters;
        }

        return newFilters;
      }

      const filterExists = prevFilters.find(
        (filter) => filter.name === dynamicFilterName
      );

      if (filterExists) {
        const updatedFilters = prevFilters.map((filter) =>
          filter.name === dynamicFilterName
            ? { ...filter, filterValues: updatedFilterValues }
            : filter
        );

        // Return the same array reference if no changes are made
        if (JSON.stringify(updatedFilters) === JSON.stringify(prevFilters)) {
          return prevFilters;
        }

        return updatedFilters;
      } else {
        const newFilters = [
          ...prevFilters,
          { name: dynamicFilterName, filterValues: updatedFilterValues },
        ];
        return newFilters;
      }
    });
  };

  const createDynamicUrlParams = (filterCategories) => {
    const params = new URLSearchParams();

    filterCategories.forEach((filter) => {
      const urlParamName = encodeURIComponent(filter.name);
      const filterValues = filter.filterValues
        .map((value) => encodeURIComponent(value))
        .join(',');

      params.set(urlParamName, filterValues);
    });

    return params;
  };

  useEffect(() => {
    if (dynamicFilterCategories.length > 0) {
      const searchParams = new URLSearchParams(location.search);
      dynamicFilterCategories.forEach((filter) => {
        searchParams.set(filter.name, filter.filterValues.join('|')); // Directly use the decoded values
      });

      const newUrl = `${location.pathname}?${searchParams.toString()}`;
      navigate(newUrl, { replace: true });
    }
  }, [dynamicFilterCategories]);

  const handleBrowseOptionChange = (options) => {
    if (!backToTopClicked) {
      handleClearAll();
      const validOptions = options.filter((option) => option);
      setBrowseOptions(validOptions);
    }
  };

  const handleLeafClassChange = (leafClasses) => {
    setLeafClassesForFilter(leafClasses[leafClasses?.length - 1]);
  };

  const handleMobileFilterIconClick = () => {
    setShowMobileFilterPane((prev) => !prev);
  };

  const handleCloseMobileFilterPane = () => {
    setShowMobileFilterPane(false);
  };

  const handleClearBrowseOptions = () => {
    setBrowseOptions([]);
    setSelectedClass('');
    setDynamicFilterCategories([]);
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('browseOptions');
    navigate({ search: searchParams.toString() });
  };

  useEffect(() => {
    if (!isLeaf) {
      setMobileFilterRowsDisabled(false);
    } else {
      setMobileFilterRowsDisabled(true);
    }
  }, [browseOptions, selectedClass]);

  useEffect(() => {
    document.title = `${searchTerm || 'Search'} results`;

    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content =
      'Search Results page within eCompletions search tool catalog';

    document.head.appendChild(metaDescription);

    return () => {
      document.title = '';
      document.head.removeChild(metaDescription);
    };
  }, []);

  const previousSearchTermRef = useRef();

  useEffect(() => {
    const newTerm = searchParams.get('term');
    if (newTerm !== previousSearchTermRef.current) {
      setSearchTerm(newTerm);
      previousSearchTermRef.current = newTerm;
    }
    if (
      previousSearchTermRef.current !== undefined &&
      previousSearchTermRef.current !== searchTerm
    ) {
      handleClearAll();
    }
    previousSearchTermRef.current = searchTerm;
  }, [searchParams]);

  useEffect(() => {
    setFilterApplyClicked(false);
  }, [filterApplyClicked]);

  // !END URL PARAMS STATE

  return (
    <SearchContext.Provider
      value={{
        onSearchTermChange: handleSearchTermChange,
        onSelectClass: handleSelectClass,
        onChangeSizeMinIdValue: handleSizeMinIdValue,
        onChangeSizeMaxIdValue: handleSizeMaxIdValue,
        onChangeSizeMinOdValue: handleSizeMinOdValue,
        onChangeSizeMaxOdValue: handleSizeMaxOdValue,
        onChangeSizeMinLength: handleSizeMinLength,
        onChangeSizeMaxLength: handleSizeMaxLength,
        onChangeDynamicFilterValue: handleDynamicFilterUpdate,
        onBrowseOptionChange: handleBrowseOptionChange,
        onPaginationRangeChange: handlePaginatedRangeChange,
        onRangeFilterChange: handleRangeFilterChange,
        onLeafClassChange: handleLeafClassChange,
        handleMobileFilterIconClick: handleMobileFilterIconClick,
        handleCloseMobileFilterPane,
        onClearAll: handleClearAll,
        isAuthModalOpen: isOpen,
        setIsLeaf: setIsLeaf,
        handleClearBrowseOptions,
        setBackToTopClicked,
        setApplyClicked,
        setFilterApplyClicked,
        applyClicked,
        filterApplyClicked,
        mutateFilter,

        authToken,
        sizeMinIdValue,
        sizeMaxIdValue,
        sizeMinOdValue,
        sizeMaxOdValue,
        sizeMinLengthValue,
        sizeMaxLengthValue,
        dynamicFilterCategories,
        selectedClass,
        propertyFilters,
        rangePropertyFilters,
        searchTerm,
        sumOfResults,
        searchWithinClassesData: data,
        browseOptions,
        leafClassesForFilter,
        showMobileFilterPane,
        mobileFilterRowsDisabled,
        filterError,
        isLoadingFilter: isLoadingFilteredSearch,
        totalPages,
        paginatedRange,
        resultsPerPageFilter,
        isLeaf: isLeaf,
        classPath: browseOptions.join('|'),
      }}
    >
      <ErrorModal />
      <div>
        <StyledContainer>
          <MainContainer>
            <FilterPaneContainer>
              <FilterPane
                isLoadingSearchWithinClasses={isLoadingSearchWithinClasses}
              />
            </FilterPaneContainer>
            {showMobileFilterPane && (
              <MobileFilterPane>
                <MobileInnerFilterContainer>
                  <FilterPane
                    isLoading={
                      isLoadingFilteredSearch || isLoadingSearchWithinClasses
                    }
                  />
                </MobileInnerFilterContainer>
              </MobileFilterPane>
            )}
            <SearchResultsContainer>
              <SearchResults
                isLoading={
                  isLoadingFilteredSearch || isLoadingSearchWithinClasses
                }
                resultsPerPage={resultsPerPageFilter}
                searchData={filterToolData?.Tools}
                rangeStart={rangeStart}
                rangeEnd={rangeEnd}
                oktaRedirectOnLoginTo={`/search?term=${searchTerm}&resultsPerPage=${resultsPerPageFilter}&paginatedRange=${paginatedRange}&selectedClass=${selectedClass}&sizeMinIdValue=${sizeMinIdValue}&sizeMaxIdValue=${sizeMaxIdValue}&sizeMinOdValue=${sizeMinOdValue}&sizeMaxOdValue=${sizeMaxOdValue}&sizeMinLengthValue=${sizeMinLengthValue}&sizeMaxLengthValue=${sizeMaxLengthValue}`}
              />
            </SearchResultsContainer>
          </MainContainer>
        </StyledContainer>
      </div>
    </SearchContext.Provider>
  );
};

export default SearchPageContainer;
