import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import ApplicationContext from '../../../ApplicationContext.js';
import { X } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { ErrorModalContext } from '../../../Modals/ErrorModal';
import ErrorModal from '../../../Modals/ErrorModal.jsx';
import DataFetchErrorPlaceholder from '../../../shared/DataFetchErrorPlaceholder.jsx';
import { useOktaAuthContext } from '../../../OktaAuthContext.js';
import { useOktaAuth } from '@okta/okta-react';

import { removeItemFromShoppingCart } from '../../../backend/shoppingcart.js';

const CartMenuContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: ${(props) => (props.$authState?.isAuthenticated ? '300px' : '240px')};
  background: white;
  top: 45px;
  transform: translateX(-50%);
  z-index: 3;
  padding: 16px 12px;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.2);
  // border-radius: 10px;
  pointer-events: ${(props) => (props.$isDisabled ? 'none' : 'auto')};

  &:after {
    content: '';
    position: absolute;
    top: -20px;
    left: 57%;
    transform: translateX(-50%);
    border: 10px solid white;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    border-bottom-color: white;
    width: 0;
    height: 0;
    @media (max-width: 1024px) {
      left: 78%;
    }
    @media (max-width: 912px) {
      left: 84%;
    }
    @media (max-width: 820px) {
      left: 86%;
    }
    @media (max-width: 768px) {
      left: 88%;
    }
    @media (max-width: 414px) {
      left: 87%;
    }
  }

  @media (max-width: 1024px) {
    right: -15%;
    top: 110px;
  }
  @media (max-width: 820px) {
    right: -17%;
  }
  @media (max-width: 540px) {
    left: 66%;
  }
  @media (max-width: 430px) {
    left: 59%;
  }
  @media (max-width: 390px) {
    left: 55%;
  }
`;

const DataFetchErrorPlaceholderContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  top: 45px;
  right: 5px;
  z-index: 3;
  width: 376px;
  height: 268px;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.2);
  // border-radius: 10px;
  pointer-events: none;

  &:after {
    content: '';
    position: absolute;
    top: -20px;
    left: 69%;
    transform: translateX(-50%);
    border: 10px solid white;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    border-bottom-color: white;
    width: 0;
    height: 0;
    @media (max-width: 1024px) {
      left: 87%;
    }
    @media (max-width: 912px) {
      left: 88%;
    }
    @media (max-width: 820px) {
      left: 89%;
    }
    @media (max-width: 768px) {
      left: 90%;
    }
    @media (max-width: 540px) {
      left: 92%;
    }
    @media (max-width: 414px) {
      left: 93%;
    }
  }

  @media (max-width: 1024px) {
    right: 1%;
    top: 110px;
  }

  @media (max-width: 380px) {
    max-width: 376px;
    width: 98%;
    left: 1%;
  }
`;

const CartListRowContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-gray4);
  margin-bottom: 8px;
  padding-bottom: 8px;
  width: 100%;
  overflow: hidden;

  opacity: ${(props) => (props.$isDisabled ? '0.5' : '1')};
`;

const CartItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 400;
  flex: 1; // Allow it to take up remaining space
  min-width: 0; // Prevent overflow from the content
`;

const CartTitle = styled.div`
  color: var(--color-gray1);
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 18px;
`;

const CartListItemButton = styled.button`
  font-family: Univers;
  width: 250px;
  font-size: 14px;
  padding-bottom: 4px;
  text-align: left;

  color: var(--color-gray1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  max-width: 170px;
  margin-right: 8px;
`;

const MaterialNumber = styled.div`
  color: #6c727d;
`;

const QuantityInput = styled.input`
  width: 30px;
  height: 20px;
  // border-radius: 6px;
  border: solid 1px var(--color-gray4);
  font-family: Univers;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin-right: 8px;

  background-color: white;
`;

const RemoveItemButton = styled.button`
  font-family: Univers;
  display: flex;
  border: none;
  background: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  width: 20px;
`;

const SeeFullCartButton = styled.button`
  font-family: Univers;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  color: var(--color-primary);
  font-weight: 400;
  font-size: 12px;
  padding-bottom: 8px;
  align-self: end;
  cursor: pointer;
  text-decoration: underline;
  margin-top: auto;
  width: 100%;
`;

const RequestQuoteButton = styled.button`
  align-self: center;
  font-family: Univers;
  font-weight: 400;
  font-size: 14px;
  border: none;
  background: none;
  width: 100%;
  height: 35px;
  // border-radius: 10px;
  background-color: ${(props) =>
    props.disabled ? 'var(--color-gray4)' : 'var(--color-primary)'};
  box-shadow: 0 1px 1px rgba(0, 0, 0, 1);
  cursor: ${(props) => (props.disabled ? '' : 'pointer')};
  color: white;
`;

const EmptyCart = styled.div`
  height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-gray3);
  font-size: 18px;
  font-weight: 500;
`;

const SkeletonRow = styled(Skeleton)`
  margin-bottom: 12px;
`;

const RemoveText = styled.div`
  font-style: italic;
  color: var(--color-primary);
  font-size: 12px;
`;

const SignInContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SignInWarningText = styled.p`
  color: var(--color-primary);
  font-weight: 600;
  font-size: 12px;
`;

const SignInWarningMessage = styled.p`
  font-size: 10px;
  color: #293a4f;
`;

const SignInButton = styled.button`
  font-family: Univers;
  width: 88px;
  height: 26px;
  font-size: 12px;
  font-weight: 500;
  background-color: var(--color-primary);
  color: #fff;
  border: unset;
  // border-radius: 8px;
  cursor: pointer;
  margin-top: 12px;

  &:hover {
    background-color: var(--color-dark-red);
  }
`;

const ShoppingCartMenu = ({ closeMenu }) => {
  const {
    shoppingCartItemsData,
    isShoppingCartLoading,
    updateShoppingCartData,
    updateCartItemsNumber,
    shoppingCartError,
  } = useContext(ApplicationContext);

  const [itemBeingRemoved, setItemBeingRemoved] = useState(null);
  const [, setError] = useContext(ErrorModalContext);
  const { openModal } = useOktaAuthContext();
  const { authState } = useOktaAuth();

  const navigate = useNavigate();

  const handleNavigateToShoppingCartView = () => {
    navigate('/shopping-cart');
    closeMenu();
  };

  const handleRedirectToToolDetailsPage = (sap) => {
    navigate(`/tool/${sap}`);
    window.location.reload();
  };

  const {
    data: updatedItemsData,
    mutate: removeFromShoppingCartList,
    isLoading: isLoadingRemoveFromShoppingCart,
  } = useMutation(
    (data) => removeItemFromShoppingCart(data.sap.toString(), data.quantity),
    {
      onError: (error) => {
        const errorCode = error.message.split('/');
        if (error.constructor.name === 'TypeError') {
          setError({
            title: 'Looks like something went wrong',
            message:
              'Network connection lost. Please check your network connection and try again.',
          });
        } else if (errorCode[1] === '401') {
          openModal('/shopping-cart');
        } else {
          setError({
            title: 'Looks like something went wrong',
            message:
              'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
            errorCode: `#SC-3-${errorCode[1]}`,
          });
        }
      },
      onSuccess: (response) => {
        updateShoppingCartData(response);
        updateCartItemsNumber(response.length);
      },
    }
  );

  const handleItemBeingRemoved = (sap, quantity) => {
    removeFromShoppingCartList({
      sap: sap,
      quantity: quantity,
    });
    setItemBeingRemoved(sap);
  };

  useEffect(() => {
    shoppingCartItemsData;
  }, [shoppingCartItemsData]);

  return (
    <>
      {' '}
      <ErrorModal />
      {shoppingCartError ||
      (!isShoppingCartLoading &&
        !Array.isArray(shoppingCartItemsData) &&
        authState?.isAuthenticated) ? (
        <DataFetchErrorPlaceholderContainer>
          <DataFetchErrorPlaceholder />
        </DataFetchErrorPlaceholderContainer>
      ) : (
        <CartMenuContainer
          $isDisabled={isLoadingRemoveFromShoppingCart}
          $isAuthenticated={authState?.isAuthenticated}
        >
          {authState?.isAuthenticated ? (
            <>
              <CartTitle>My cart</CartTitle>
              {isShoppingCartLoading ? (
                <SkeletonRow
                  width="240px"
                  height={'30px'}
                  count={4}
                ></SkeletonRow>
              ) : shoppingCartItemsData?.length === 0 ? (
                <>
                  <EmptyCart>Cart empty</EmptyCart>
                  <RequestQuoteButton disabled>
                    REQUEST A QUOTE
                  </RequestQuoteButton>
                </>
              ) : (
                <>
                  {shoppingCartItemsData?.slice(0, 3).map((item, index) => (
                    <CartListRowContainer
                      key={index}
                      $isDisabled={isLoadingRemoveFromShoppingCart}
                    >
                      <CartItemContainer>
                        <CartListItemButton
                          onClick={() =>
                            handleRedirectToToolDetailsPage(item.SapNumber)
                          }
                        >
                          {item.Name}
                        </CartListItemButton>
                        <MaterialNumber>
                          Material# {item.SapNumber}
                        </MaterialNumber>
                      </CartItemContainer>
                      {isLoadingRemoveFromShoppingCart &&
                      itemBeingRemoved === item.SapNumber ? (
                        <RemoveText>Removing item...</RemoveText>
                      ) : (
                        <>
                          <QuantityInput
                            disabled
                            value={item.Quantity}
                            aria-label="quantity"
                          />
                          <RemoveItemButton
                            aria-label="remove item from shopping cart"
                            onClick={() =>
                              handleItemBeingRemoved(
                                item.SapNumber,
                                item.Quantity
                              )
                            }
                          >
                            <X
                              height={12}
                              color={'var(--color-primary)'}
                              strokeWidth={3}
                            />
                          </RemoveItemButton>
                        </>
                      )}
                    </CartListRowContainer>
                  ))}
                  <SeeFullCartButton onClick={handleNavigateToShoppingCartView}>
                    See full cart &gt;
                  </SeeFullCartButton>
                  <RequestQuoteButton
                    onClick={handleNavigateToShoppingCartView}
                  >
                    REQUEST A QUOTE
                  </RequestQuoteButton>
                </>
              )}
            </>
          ) : (
            <SignInContainer>
              <SignInWarningText>Sign in required</SignInWarningText>
              <SignInWarningMessage>
                Please sign into your account to access this feature
              </SignInWarningMessage>
              <SignInButton onClick={() => openModal()}>SIGN IN</SignInButton>
            </SignInContainer>
          )}
        </CartMenuContainer>
      )}
    </>
  );
};

export default ShoppingCartMenu;
