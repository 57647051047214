import React from 'react';
import styled from 'styled-components';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

const SplideContainer = styled(Splide)`
  height: 42px;
  background-color: var(--color-primary);
  width: 100%;

  @media (max-width: 1024px) {
    height: 80px;
  }

  .splide__pagination {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }

  .splide__arrow {
    background: none;
  }

  .splide__arrow--prev {
    left: 20%;
    @media (max-width: 820px) {
      left: 4%;
    }
  }

  .splide__arrow--next {
    right: 20%;
    @media (max-width: 820px) {
      right: 4%;
    }
  }

  .splide__arrow svg {
    fill: #fff;
    height: 1em;
    width: 1em;
  }

  .splide__pagination__page {
    margin: 0 3px;
    background: white;
    border: 0.5px solid var(--color-gray3);
    height: 7px;
    width: 7px;
  }
  .splide__pagination__page.is-active {
    background: var(--color-primary);
    transform: none;
    opacity: 1;
    border: none;
  }
`;

const SlideContainer = styled.div`
  display: flex;
  width: 100%;
  height: 42px;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
    width: 80%;
    height: 80px;
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
  }
`;

const SlideContent = styled.p`
  margin: 0;
  color: #fff;
  font-family: Univers;
  font-size: 16px;
`;

const SlideLink = styled.a`
  text-decoration-color: white;
  color: #fff;
  font-family: Univers;
  font-size: 16px;
  padding-left: 4px;
`;

const CallToActionCarousel = () => {
  const splideOptions = {
    type: 'loop',
    heightRatio: 0.5,
    arrows: true,
    pagination: false,
  };

  return (
    <SplideContainer options={splideOptions}>
      <SplideSlide>
        <SlideContainer>
          <SlideContent>
            <SlideLink
              href="
https://www.halliburton.com/en/completions/well-completions/multilateral-systems"
              target="_blank"
            >
              Multilateral systems
            </SlideLink>
            :&nbsp;Increase production, extend field life
          </SlideContent>
        </SlideContainer>
      </SplideSlide>

      <SplideSlide>
        <SlideContainer>
          <SlideContent>
            <SlideLink
              href="https://www.halliburton.com/en/completions/well-completions/production-packers"
              target="_blank"
            >
              Production packers
            </SlideLink>
            :&nbsp;Customized designs, reliable performance
          </SlideContent>
        </SlideContainer>
      </SplideSlide>

      <SplideSlide>
        <SlideContainer>
          <SlideContent>
            <SlideLink
              href="https://www.halliburton.com/en/completions/well-completions/subsurface-flow-controls"
              target="_blank"
            >
              Subsurface flow controls
            </SlideLink>
            :&nbsp;Delivering reliable equipment for over 70 years
          </SlideContent>
        </SlideContainer>
      </SplideSlide>
      <SplideSlide>
        <SlideContainer>
          <SlideContent>
            <SlideLink
              href="https://www.halliburton.com/en/completions/well-completions/isolation-barrier-valves"
              target="_blank"
            >
              Isolation barrier valves
            </SlideLink>
            :&nbsp; Complete formation isolation and fluid-loss control
          </SlideContent>
        </SlideContainer>
      </SplideSlide>
      <SplideSlide>
        <SlideContainer>
          <SlideContent>
            <SlideLink
              href="https://www.halliburton.com/en/completions/well-completions/liner-hangers"
              target="_blank"
            >
              Liner hangers
            </SlideLink>
            :&nbsp;Solutions for any well environment
          </SlideContent>
        </SlideContainer>
      </SplideSlide>
    </SplideContainer>
  );
};

export default CallToActionCarousel;
