import * as siteConfig from '../siteconfig';
import { oktaAuth } from '../services/okta-service';
import { appInsights } from '../appInsights.js';

export const addItemToFavorites = async (sapNumber) => {
  const accessToken = (await oktaAuth.tokenManager.get('accessToken'))
    ?.accessToken;
  const idToken = (await oktaAuth.tokenManager.get('idToken'))?.idToken;
  try {
    const response = await fetch(
      `${siteConfig.searchApiUrl}/favorites/${sapNumber}`,
      {
        method: 'POST',
        headers: {
          accept: 'text/plain',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'X-OktaIdToken': idToken,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to add item to favorites /${response.status}/`);
    }
    return response.json();
  } catch (error) {
    appInsights.trackException({ error });
    throw error;
  }
};

export const fetchFavorites = async () => {
  const accessToken = (await oktaAuth.tokenManager.get('accessToken'))
    ?.accessToken;
  const idToken = (await oktaAuth.tokenManager.get('idToken'))?.idToken;

  try {
    const response = await fetch(
      `${siteConfig.searchApiUrl}/favorites`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-OktaIdToken': idToken,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch favorites /${response.status}/`);
    }
    return response.json();
  } catch (error) {
    appInsights.trackException({ error });
    throw error;
  }
};

export const removeItemFromFavorites = async (sapNumber) => {
  const accessToken = (await oktaAuth.tokenManager.get('accessToken'))
    ?.accessToken;
  const idToken = (await oktaAuth.tokenManager.get('idToken'))?.idToken;
  try {
    const response = await fetch(
      `${siteConfig.searchApiUrl}/favorites/${sapNumber}`,
      {
        method: 'DELETE',
        headers: {
          accept: 'text/plain',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'X-OktaIdToken': idToken,
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        `Failed to remove item from favorites /${response.status}/`
      );
    }
    return response.json();
  } catch (error) {
    appInsights.trackException({ error });
    throw error;
  }
};
