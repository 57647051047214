import * as siteConfig from '../siteconfig';
import { oktaAuth } from '../services/okta-service';
import { appInsights } from '../appInsights.js';

// Search Results page calls

export const searchWithinClasses = async (keyword) => {
  const accessToken = (await oktaAuth.tokenManager.get('accessToken'))
    ?.accessToken;
  try {
    const response = await fetch(
      `${siteConfig.searchApiUrl}/tools/search/classes?keyword=${keyword}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          keyword: keyword,
        },
      }
    );
    if (!response.ok) {
      throw new Error(
        `Failed to search for tools within classes /${response.status}/`
      );
    }
    return response.json();
  } catch (error) {
    appInsights.trackException({ error });
    throw error;
  }
};

export const fetchClasses = async () => {
  const accessToken = (await oktaAuth.tokenManager.get('accessToken'))
    ?.accessToken;
  try {
    const response = await fetch(`${siteConfig.searchApiUrl}/classes`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (!response.ok) {
      throw new Error(`Failed to fetch classes /${response.status}/`);
    }
    return response.json();
  } catch (error) {
    appInsights.trackException({ error });
    throw error;
  }
};
