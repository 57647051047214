import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import InstructionsQR from '../../../media/icons/did-you-know-section/qr-code.svg';
import InstructionsQuickAccess from '../../../media/icons/did-you-know-section/grid.svg';
import HalLogo from '../../../media/icons/did-you-know-section/hal-logo.svg';

const ComponentContainer = styled.div`
  font-family: Univers;
  width: 97%;
  background-color: #6d757a;
  // border-radius: 8px;
  padding: 16px;
  margin-bottom: 100px;
  @media (max-width: 1024px) {
    width: 96%;
  }

  @media (max-width: 430px) {
    width: 92%;
  }
`;

const Header = styled.div`
  color: #ffffff;
  padding-bottom: 14px;
  text-align: center;
  font-weight: 500;
  font-size: 28px;
`;

const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Card = styled.button`
  font-family: Univers;
  color: var(--color-gray1);
  width: 32%;
  padding: 15px;
  text-align: center;
  margin: 0;
  border: none;
  background: none;
  background-color: white;
  // border-radius: 8px;
  cursor: ${(props) => (props.$notClickable ? '' : 'pointer')};

  @media (max-width: 900px) {
    width: 100%;
    margin-bottom: 18px;
  }
`;

const CardImage = styled.img`
  width: 45px;
  height: 45px;
  padding-right: 15px;
`;

const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

const CardDescription = styled.div`
  font-size: 12px;
`;

const DidYouKnowSection = () => {
  const navigate = useNavigate();

  const handleGoToInstructions = () => {
    navigate('/instructions');
  };

  const handleRedirectToHalliburton = () => {
    const externalUrl = 'https://www.halliburton.com';
    window.open(externalUrl, '_blank');
  };

  return (
    <ComponentContainer>
      <Header>Did you know?</Header>
      <CardsContainer>
        <Card $notClickable>
          <CardImage src={InstructionsQR} alt="qr code example" />
          <CardInfo>
            <CardTitle>Scan with a QR code</CardTitle>
            <CardDescription>
              Use your camera or tablet phone to scan a QR code.
            </CardDescription>
          </CardInfo>
        </Card>

        <Card
          onClick={() => handleGoToInstructions()}
          aria-label="navigate-to-quick-access-instructions"
        >
          <CardImage src={InstructionsQuickAccess} alt="" />
          <CardInfo>
            <CardTitle>Add to home screen</CardTitle>
            <CardDescription>
              You can add this website as a quicklink on your device homescreen.
            </CardDescription>
          </CardInfo>
        </Card>

        <Card
          onClick={() => handleRedirectToHalliburton()}
          aria-label="navigate-to-halliburton.com"
        >
          <CardImage src={HalLogo} alt="halliburton logo" />
          <CardInfo>
            <CardTitle>Halliburton.com</CardTitle>
            <CardDescription>
              Click here to experience the full Halliburton website.
            </CardDescription>
          </CardInfo>
        </Card>
      </CardsContainer>
    </ComponentContainer>
  );
};

export default DidYouKnowSection;
