import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as siteConfig from '../../../siteconfig';
import { useNavigate } from 'react-router-dom';
import { useOktaAuthContext } from '../../../OktaAuthContext.js';
import { useOktaAuth } from '@okta/okta-react';

const MyAccountMenuContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 170px;
  height: 130px;
  background: white;
  top: 45px;
  left: 84%;
  transform: translateX(-50%);
  z-index: 3;
  padding: 16px 12px;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.2);
  // border-radius: 10px;

  &:after {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    border: 10px solid white;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    border-bottom-color: white;
    width: 0;
    height: 0;
  }
`;

const MyAccountListItem = styled.button`
  background: none;
  border: none;
  margin-left: 0;
  font-family: Univers;
  font-size: 14px;
  line-height: 22px;
  cursor: ${(props) => (props.$isTitle ? '' : 'pointer')};
  font-weight: ${(props) => (props.$isTitle ? '500' : '400')};
  margin-bottom: 12px;
  color: var(--color-gray1);

  &:hover {
    color: ${(props) => !props.$isTitle && '#cc0000'};
  }

  @media (max-width: 1024px) {
    margin-top: 12px;
    margin-bottom: ${(props) => (props.$isTitle ? '8px' : '0')};
    font-size: ${(props) => (props.$isTitle ? '18px' : '16px')};
    padding: 0;
  }
`;

const AnchorWrapper = styled.div`
  padding: 0;
  margin-top: 12px;
`;

const MyAccountAnchorItem = styled.a`
  text-decoration: none;
  color: var(--color-gray1);
  font-family: Univers;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 0px;
  padding-left: 6px;

  &:hover {
    color: ${(props) => !props.$isTitle && '#cc0000'};
  }

  @media (max-width: 1024px) {
    font-size: 16px;
    padding: 0;
  }
`;

const MobileMyAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-left: 28px;
`;

const Footer = styled.div`
  margin-top: 22px;
  height: 100%;
  background-color: var(--color-gray4);
`;

const MyAccountMenu = ({ mobile, closeMenu, closeMobileMenu }) => {
  const navigate = useNavigate();
  const { openModal } = useOktaAuthContext();
  const { authState } = useOktaAuth();
  const haltraxLink = siteConfig.haltraxUrl;

  const handleFavoritesClick = () => {
    mobile ? closeMobileMenu() : closeMenu();

    if (authState?.isAuthenticated) {
      navigate(`/favorites`);
    } else {
      openModal(`/favorites`);
    }
  };

  const handlePreviousQuotesClick = () => {
    mobile ? closeMobileMenu() : closeMenu();
    if (authState?.isAuthenticated) {
      navigate(`/previous-requests`);
    } else {
      openModal(`/previous-requests`);
    }
  };

  return (
    <>
      {!mobile ? (
        <MyAccountMenuContainer>
          <MyAccountListItem $isTitle>My account</MyAccountListItem>
          <MyAccountListItem onClick={handleFavoritesClick}>
            Favorites
          </MyAccountListItem>
          <MyAccountListItem onClick={handlePreviousQuotesClick}>
            Previous requests
          </MyAccountListItem>
          <MyAccountAnchorItem href={haltraxLink} target="_blank">
            HalTrax
          </MyAccountAnchorItem>
        </MyAccountMenuContainer>
      ) : (
        <>
          <MobileMyAccountContainer>
            <MyAccountListItem $isTitle>My account</MyAccountListItem>
            <MyAccountListItem onClick={handleFavoritesClick}>
              Favorites
            </MyAccountListItem>
            <MyAccountListItem onClick={handlePreviousQuotesClick}>
              Previous requests
            </MyAccountListItem>
            <AnchorWrapper>
              {' '}
              <MyAccountAnchorItem href={haltraxLink} target="_blank">
                HalTrax
              </MyAccountAnchorItem>
            </AnchorWrapper>
          </MobileMyAccountContainer>
          <Footer />
        </>
      )}
    </>
  );
};

export default MyAccountMenu;
