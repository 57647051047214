import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from 'react-query';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { oktaAuth } from './services/okta-service';
import { Security } from '@okta/okta-react';
import './../css/theme.css';
import './../css/app.css';

import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './appInsights';

import LoginPage from './Login/LoginPage';
import LogoutPage from './Logout/LogoutPage';

import LandingPage from './views/LandingPage/index';
import ShoppingCart from './views/ShoppingCart';
import FavoritesPage from './views/Favorites';
import PreviousQuotes from './views/PreviousQuotes';
import QuickAccessInstructionsPage from './views/QuickAccessInstructionsPage/index';
import SearchPage from './views/SearchPage/index';
import ToolDetailsPage from './views/ToolDetailsPage/index';
import ToolComparisonPage from './views/ToolComparisonPage/index.jsx';
import BrowsePage from './views/Browse/index';
import ChatBot from './ChatBot/ChatBot';
import Footer from './shared/Footer/Footer';
import PageHeader from './shared/PageHeader';
import DockedPanel from './shared/DockedPanel';

import ApplicationContext from './ApplicationContext.js';
import { ErrorModalContext } from './Modals/ErrorModal';
import ErrorModal from './Modals/ErrorModal.jsx';
import OktaAuthModal from './Modals/OktaAuthModal.jsx';
import { OktaAuthProvider } from './OktaAuthContext.js';

import { fetchClasses } from './backend/search.js';
import { fetchShoppingCartItems } from './backend/shoppingcart.js';

const App = () => {
  const restoreOriginalUri = async (oktaAuth) => {
    oktaAuth.setOriginalUri(window.location.origin);
  };
  const [classType, setClassType] = useState('');
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const [itemsInCart, setItemsInCart] = useState(0);
  const [, setError] = useContext(ErrorModalContext);
  const [shoppingCartError, setShoppingCartError] = useState(false);
  const [shoppingCartData, setShoppingCartData] = useState(null);
  const [userName, setUserName] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const cachedUserName = localStorage.getItem('userName');
    if (cachedUserName) {
      setUserName(cachedUserName);
    } else {
      if (isAuthenticated) {
        oktaAuth
          .getUser()
          .then((userInfo) => {
            const username = userInfo.name;
            setUserName(username);
            localStorage.setItem('userName', username);
            localStorage.setItem(
              'isHalUser',
              userInfo.email.includes('@halliburton.com')
            );
          })
          .catch((error) => {
            console.error('Error retrieving user information:', error);
          });
      } else {
        console.error('Access token is missing or invalid.');
      }
    }
  }, [isAuthenticated]);

  const handleResize = () => {
    const width = window.innerWidth;
    setScreenWidth(width);

    if (width < 1025) {
      setClassType('compact');
    } else {
      setClassType('');
    }
  };

  useEffect(() => {
    setIsPageLoaded(true);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isPageLoaded) {
      if (screenWidth < 1025) {
        setClassType('compact');
      } else {
        setClassType('');
      }
    }
  }, [screenWidth, isPageLoaded]);

  let cachedClasses = JSON.parse(localStorage.getItem('fetchedClasses'));

  const {
    data: classesData,
    isLoading: classesIsLoading,
    error: classesError,
  } = useQuery('fetchClasses', fetchClasses, {
    enabled: !cachedClasses,
    onError: (error) => {
      const errorCode = error.message.split('/');
      if (error.constructor.name === 'TypeError') {
        setError({
          title: 'Looks like something went wrong',
          message:
            'Network connection lost. Please check your network connection and try again.',
        });
      } else {
        setError({
          title: 'Looks like something went wrong',
          message:
            'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
          errorCode: `#CL-1-${errorCode[1]}`,
        });
      }
    },
  });

  useEffect(() => {
    if (classesData && !cachedClasses) {
      localStorage.setItem('fetchedClasses', JSON.stringify(classesData));
      cachedClasses = classesData;
    }
  }, [classesData, cachedClasses]);

  const {
    data: shoppingCartItemsData,
    isLoading: isShoppingCartLoading,
    error,
  } = useQuery('fetchShoppingCartItems', fetchShoppingCartItems, {
    enabled: isAuthenticated,
    onSuccess: (response) => {
      setShoppingCartError(false);
      setShoppingCartData(response);
      setItemsInCart(response.length);
    },
    onError: (error) => {
      const errorCode = error.message.split('/');
      if (error.constructor.name === 'TypeError') {
        setError({
          title: 'Looks like something went wrong',
          message:
            'Network connection lost. Please check your network connection and try again.',
        });
        setShoppingCartError(true);
      } else if (errorCode[1] === '401') {
      } else {
        setError({
          title: 'Looks like something went wrong',
          message:
            'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
          errorCode: `#SC-1-${errorCode[1]}`,
        });
        setShoppingCartError(true);
      }
    },
  });

  return (
    <div className="app">
      <ErrorModal />

      <AppInsightsContext.Provider value={reactPlugin}>
        <AppInsightsErrorBoundary
          appInsights={reactPlugin}
          onError={() => <h1>A breaking error has occurred</h1>}
        >
          <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <ApplicationContext.Provider
              value={{
                userName: userName,
                classType: classType,
                shoppingCartItemsData: shoppingCartData,
                shoppingCartItemsNumber: itemsInCart,
                shoppingCartError: shoppingCartError,
                updateCartItemsNumber: setItemsInCart,
                updateShoppingCartData: setShoppingCartData,
                classesData: classesData,
                classesIsLoading: classesIsLoading,
                classesLoadingError: classesError,
                isShoppingCartLoading: isShoppingCartLoading,
              }}
            >
              <BrowserRouter>
                <OktaAuthProvider>
                  <PageHeader setIsAuthenticated={setIsAuthenticated} />
                  <DockedPanel />
                  <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/logout" element={<LogoutPage />} />
                    <Route path="/" element={<LandingPage />} />
                    <Route path="shopping-cart" element={<ShoppingCart />} />
                    <Route path="favorites" element={<FavoritesPage />} />
                    <Route
                      path="previous-requests"
                      element={<PreviousQuotes />}
                    />
                    <Route path="browse" element={<BrowsePage />} />
                    <Route
                      path="instructions"
                      element={<QuickAccessInstructionsPage />}
                    />
                    <Route path="search" element={<SearchPage />} />
                    <Route path="tool/:id" element={<ToolDetailsPage />} />
                    <Route
                      path="search/tool-comparison"
                      element={<ToolComparisonPage />}
                    />
                    <Route
                      path="*"
                      exact={true}
                      element={<Navigate to="/" replace={true} />}
                    />
                  </Routes>
                  <OktaAuthModal />
                </OktaAuthProvider>
              </BrowserRouter>
              <Footer />
              {/* <ChatBot /> */}
            </ApplicationContext.Provider>
          </Security>
        </AppInsightsErrorBoundary>
      </AppInsightsContext.Provider>
    </div>
  );
};

export default App;
